import React from 'react'
import { Link } from "gatsby"
import './dealerCard.scss'

const DealerCard = (props) => {
    const {dealer, dealerSettings} = props
    
    const foundDealer = dealerSettings.find(el => el.dealerName === dealer.dealerName)

    return (
        <div className="dealer__container">
            <h2 className="title">{dealer.dealerName}</h2>
            <img className="image" src={dealer.dealerImage}/>
            <div className="buttons">
                <Link className="button" to={`${foundDealer.inventoryURL}`} target="_blank">VIEW<br/>INVENTORY</Link>
                <Link className="button" to={`${dealer.dealerWebsiteLink?dealer.dealerWebsiteLink:dealer.websiteLink }`} target="_blank">VIEW<br/>DEALER WEBSITE</Link>
            </div>
            <Link className="link" to={`${foundDealer.offersPageURL}`} target="_blank">VISIT DEALER OFFERS</Link>
            {dealer.distances ? <p className="distance">{dealer.distances.distance.text} Away</p> : ''} 
        </div>
    )
}

export default DealerCard