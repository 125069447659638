import React from 'react'
import './dealerFilter.scss'
import { getSiblings } from '../utility/offerFunctions'

const DealerFilter = (props) => {
    const {setContentType, contentType, setModel} = props

    const handleClick = (e, setModel, setContentType) => {
        let siblings = getSiblings(e.currentTarget)
        siblings.map(sibling => {
            if (sibling.classList.contains('selected')) {
                sibling.classList.remove('selected')
                e.currentTarget.classList.add('selected')
                setContentType(e.currentTarget.id)
            }
        })

        return setModel('none')
    }

    return (
        <button id='Dealer' className={contentType === 'Dealer' ? `findDealer__button selected` : `findDealer__button`} onClick={(e) => handleClick(e, setModel, setContentType)}>FIND A DEALER <span className="arrow"/></button>
    )
}

export default DealerFilter