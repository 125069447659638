import React, { useState, useEffect, useContext, useRef, useMemo, isValidElement} from "react";
import { FindDealerContext }  from "../../findDealer/context/findDealerContext";
import view from "./findDealer.module.scss";
import Loader from "react-loader-spinner";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMapMarker} from '@fortawesome/free-solid-svg-icons';
import PhoneIcon from '../../findDealer/assets/phoneIcon';
import RoadSignIcon from '../../findDealer/assets/roadSignIcon';
import DealerCard from '../cards/DealerCard'
const { siteMetadata } = require('../../../../gatsby-config');

const FindDealer = (props) =>{
  
  //Define context
  const findDealerProps = useContext(FindDealerContext);

  /**
   * Define values from findDealerContext
   * 
   * @param {method} searchZipCode calls three other functions from context setZipcode, isThereHistory, distanceRef
   * and passes the zipcode searched.
   */

  const {distanceRef, loadingList, setLoadingList, searchZipCode, setClosestDealerCompPresent, getDirections, scrollToListRef} = findDealerProps;
  
  //If the reference is loaded it's safe to display list, set the state of loading to false
  useEffect(()=>{
    
    if(updatedDataRef != null){
    
      setLoadingList(false);
    
    }

  },[updatedDataRef]);

  //Define states and context
  const searchMarker = <FontAwesomeIcon icon={faMapMarker} />

  //Parsed data from the distanceRef since it's a string in localStorage
  const updatedDataRef = JSON.parse(distanceRef.current.newItem );

  useEffect(()=>{

    if(isValidElement(<DealerList/>)){
      setClosestDealerCompPresent(true);
    }

  });

  const DealerList = () => updatedDataRef.closest_dealers.map((dealer, i) =>{
    return(
      <li className={view["dealer-list-item"]} style={{direction:'ltr'}} key={i}>     
        <DealerCard dealerSettings={props.dealers} dealer={dealer} key={i}/>
      </li>
    )
  });

  const DealerSearch = () => {
        
    const [formZip, setFormZip] = useState('');
    const searchHeightRef = useRef();
    const [searchBarHeight, setSearchBarHeight]= useState('');

    useEffect(() => {
      
      const divElement = searchHeightRef.current;
      setSearchBarHeight(divElement?.clientHeight);

    }, []);

    function holdFormZip(val){
      setFormZip(val);
    }

    return (

      <div className={view["left-wrap"]}>
        {/* <form ref={searchHeightRef} className={view["search-cont"]} onSubmit={()=>searchZipCode(formZip)}>
          <input className={view["search-dir"]} placeholder={searchMarker ,"Enter Zipcode"} maxlength="5" style={{display:'block', width:'100%'}} required name="setZip" label="zip code" type="text" wrap="hard" onChange={(e)=>holdFormZip(e.target.value)}/>
          <button className={view["search-btn"]}  onClick={e=>(e.preventDefault(), searchZipCode(formZip))}>
            <p style={{ position: "relative", top: "-6px", color:'#f4f5f7'}}>
              &#x2315;
            </p>
          </button>
        </form> */}
        <div className={view["search-bar-group"]}>
          <svg className={view["ping-icon"]} width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.00001 0C3.58174 0 0 3.58172 0 8.00001C0 9.47759 0.289214 11.0037 1.11917 12.1036L8.00001 21.2228L14.8808 12.1036C15.6347 11.1046 16 9.33837 16 8.00001C16 3.58172 12.4183 0 8.00001 0ZM8.00001 4.63342C9.85906 4.63342 11.3666 6.14096 11.3666 7.99999C11.3666 9.85906 9.85906 11.3666 8.00001 11.3666C6.14096 11.3666 4.63343 9.85906 4.63343 8.00001C4.63343 6.14096 6.14096 4.63342 8.00001 4.63342Z" fill="#007CC2"/></svg>
          <form ref={searchHeightRef} className={view["search-cont"]} onSubmit={()=>searchZipCode(formZip)}>
            <input className={view["search-dir"]} placeholder={searchMarker ,"Enter Zipcode"} maxlength="5" style={{display:'block', width:'100%'}} required name="setZip" label="zip code" type="text" wrap="hard" onChange={(e)=>holdFormZip(e.target.value)}/>
          </form>
          <svg onClick={e=>(e.preventDefault(), searchZipCode(formZip))} className={view["search-icon"]} width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="56" height="56" rx="28" fill="#007CC2"/><path fill-rule="evenodd" clip-rule="evenodd" d="M38.6956 27.1478C38.6956 33.0284 33.9284 37.7956 28.0478 37.7956C22.1672 37.7956 17.4 33.0284 17.4 27.1478C17.4 21.2672 22.1672 16.5 28.0478 16.5C33.9284 16.5 38.6956 21.2672 38.6956 27.1478ZM35.5792 36.0335C33.5485 37.7564 30.9195 38.7956 28.0478 38.7956C21.6149 38.7956 16.4 33.5807 16.4 27.1478C16.4 20.7149 21.6149 15.5 28.0478 15.5C34.4807 15.5 39.6956 20.7149 39.6956 27.1478C39.6956 30.349 38.4042 33.2486 36.3139 35.354L41.6573 40.6974L40.9502 41.4045L35.5792 36.0335Z" fill="white"/></svg>
        </div>

        {loadingList?
          <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%'}}>
            <Loader type="TailSpin" color="blue" height={80} width={80} />
          </div>

          :
          
          <ul className={view["search-list"]}>
            <DealerList/>
          </ul>
          
          
        }
      </div>

    );

  };

  return(
    <div id="top" style={{position:'relative', display:'block', width: '100%', height: '100%'}}>
      <div className={view["directions"]}>
        <div className={view["dir-left"]} ref={scrollToListRef}>
            <DealerSearch/>
        </div>
      </div>
    </div>
  );

}

export default FindDealer;

